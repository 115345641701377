import { ISectionComponentProps } from '../utils'
import DefaultFormLayout from './Layout/DefaultForm'

function FormsContainer(props: ISectionComponentProps) {
    const { data: { form } } = props

    if (!form) {
        return null
    }
    
    return <DefaultFormLayout {...props} />
}

export default FormsContainer