import { ISectionComponentProps } from 'components/Sections/utils';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router'
import TextBaseLayout from 'components/Sections/Text/TextBaseLayout';
import { StartPage } from '@/shared/models/Steps';
import { useMemo } from 'react';
import moment from 'moment';
import { sectionMessages } from 'components/Sections/messages';

function DefaultFormLayout(props: ISectionComponentProps) {
    const { data, section } = props
    const { form } = data
    const router = useRouter()
    const intl = useIntl()
    const path = router.asPath
    const formLink = `/steps/view/form_select_account/${form.id}?steps=view_form_select_account,view_form_personal_data,view_form_questions,view_form_payments&start_page=${StartPage.WEB_PAGE}&path=${path}`
    const isExpired = useMemo(() => {
        if(form.end_date && moment(form.end_date).isBefore()) {
            return true
        }
        if(!form.payment_packages?.every(e => e.payment_package.valid)) {
            return true
        }
        return false;
    }, [form])

    return (
        <TextBaseLayout
            alignment={section?.customize?.format?.alignment}
            title={form.name}
            paragraph={isExpired ? intl.formatMessage(sectionMessages['forms.form_expired']) : form.description}
            button={!isExpired}
            buttonLink={formLink}
            buttonText={form.button_text || intl.formatMessage({ id: 'form.sign.up', defaultMessage: '###'})}
            openInNewWindow={true}
            isClubExternalLink
        />
    )
}

export default DefaultFormLayout